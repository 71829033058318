
/*
 *   File : theme.scss
 *   Author : https://evoqins.com
 *   Description : CSS file
 *   Integrations : NA
 *   Version : 1.0.0
*/

[data-theme="light"] {
    --primary-color: #1177bd;
    --body-bg-color: #fff;
    --text-color: #222b38;
    --sidebar-color: #f3f8fb;
    --header-color: #fff;
    --input-bg: #fff;
    --footer-bg-color: #f8fafc;
    --portfolio-summary-card-bg: #ecfaf0;
    --portfolio-summary-card-bg-img: url('../Assets/Images/dashboard/investment-card-bg.png');
    --card-bg-color-common: #fff;
    --portfolio-breakdown-bg: #ddf6e4;
    --bg-greenish-yellow: #f6fff2;
    --quick-invest-card-bg: #F0FFFB;
    --border-anti-flash-white: #f2f2f2;
    --bg-powder-blue: #ECF7FF;
    --dashboard-goal-list-bg: #f3f8fb;
    --border-bright-gray: #ecedef;
    --news-card-bg: #fff;
    --sip-calculator-bg: #f5f5ff;
    --calculator-value-card: #fffcfc;
    --color-davys-grey: #555555;
    --color-cadet-grey: #9299b5;
    --color-outer-space: #44475b;
    --bg-lavender: #EAEBFF;
    --bg-pale-lavender: #D9DBFF;
    --fund-search-dropdown-bg: #FFF;
    --invest-breakdown-bg: #f1f9fe;
    --modal-bg-color: #fff;
    --fund-compare-card-bg: #E5F5FF;
    --bg-ice-blue: #F1F8FE;
    --bg-alabaster: #F8F8F8;
    --bg-sip-detail-value-card: #f9f9f9;
    --bg-profile-summary-card: #eefaf7;
    --bg-start-invest-banner: #FFFDF5;
    --bg-external-fund-imported: #FFF7F7;
    --chart-tooltip-bg: #fff;
    --bg-goal-banner: #d7f7e1;
    --bg-mint-green:#f4fef5;
    --input-label-color: #222b38;
    --pale-mint-green-bg: #e3ffe4;
    --tea-green-bg: #c1fac3;
    --off-white-bg: #f7f8ff;
    --minty-cream-bg: #f5ffed;
    --bg-baby-powder: #fcfff8;
    --very-light-gray: #FBFBFB;
    --color-charleston-green: #252c32;
    --pearl-aqua-bg: #84dcc8;
    --border-pearl-aqua: #84dcc8;
    --bg-milk: #f9fff4;
    --gray-light: #808080;
    --border-gainsboro: #dfdfdf;
    --light-blue: #ebf3ff;
    --bg-azure: #effef9;
    --bg-magnolia: #f5f5ff;
    --floral-white: #fffaf2;
    --very-light-purple: #F4EFFA;
    --pale-baby-blue: #DEF2FF;
    --light-pastel-blue-gray:#eefaf7;
    --outer-space-color: #44475b;
    --bg-chart-tooltip: #fff;
    --cosmic-latte: #fff8eb;
    --color-red: #ff0000;
    --ufo-green: #27cf5e;
    --sea-green: #209e49;
    --antique-white-bg: #ffe9d9;
    --sunset-blaze: #FF7518;
    --yellow-orange: #ffad31;
    --pale-steel-blue:#f1f9fe;
    --dark-spring-green: #1a823c;
    --eerie-black-text: #1a1a1a;
    --pure-white: #FBFBFF;
    --pale-yellow: #FFFEF7;
    --mint-cream: #f7fffd;
    --light-powder-blue: #F1F6FF;
    --icon-black:#292D32;
    --ivory: #FFFDF5;
    --dark-jungle-green: #101828;
    --frosty-cyan: #FBFFFE;
    --light-bubbles:#e6f5ff;
    --grayish-white:#FAFAFA;
    --almost-black: #010101;
    --light-silver: #d0d5dd;
    --light-sky-blue: #f1f9ff;
    --soft-pink:#FEE2E2;
    --bubbles: #e4f4ff;
    --pastel-turquoise:#c2f1e6;
    --pastel-sky-blue: #bfe5ff;
    --celadon: #D5FBD9;
    --icy-blue: #f7fbff;
    --azure-mist: #F7F9FF;
    --pale-cyan:#EFF9FF;
    --beige: #FFF3EB;
    --greenish-white: #F3FFF7;
    --very-light-alice-blue: #F0F9FF;
    --dark-silver: #676d75;
    --chart-center-text: #44475b;
    --aero-blue: #c7eee5;
    --pale-sky-blue: #EDF8FF;
}


[data-theme="dark"] {
    --primary-color: #1177bd;
    --body-bg-color: #0D0D0D;
    --text-color: #fff;
    --sidebar-color: #1A1A1A;
    --header-color: #121212;
    --input-bg: #353539;
    --footer-bg-color: #1D1D20;
    --portfolio-summary-card-bg: #191919;
    --portfolio-summary-card-bg-img: url('../Assets/Images/dashboard/investment-card-bg-dark.png');
    --card-bg-color-common: #252525;
    --portfolio-breakdown-bg: #353539;
    --bg-greenish-yellow: #191919;
    --quick-invest-card-bg: #0D0D0D;
    --border-anti-flash-white: #353539;
    --bg-powder-blue: #0D0D0D;
    --dashboard-goal-list-bg: #13151B;
    --border-bright-gray: #353539;
    --news-card-bg: #141414;
    --sip-calculator-bg: #191919;
    --calculator-value-card: #252525;
    --color-davys-grey: #fff;
    --color-cadet-grey: #fff;
    --color-outer-space: #fff;
    --bg-lavender: #191919;
    --bg-pale-lavender: #252525;
    --fund-search-dropdown-bg: #18181F;
    --invest-breakdown-bg: #191919;
    --modal-bg-color: #0D0D0D;
    --fund-compare-card-bg: #252525;
    --bg-ice-blue: #252525;
    --bg-alabaster: #18181F;
    --bg-sip-detail-value-card: #252525;
    --bg-profile-summary-card: #252525;
    --bg-start-invest-banner: #141414;
    --bg-external-fund-imported: #141414;
    --chart-tooltip-bg: #141414;
    --bg-goal-banner: #252525;
    --bg-mint-green:#252525;
    --input-label-color: #fff;
    --pale-mint-green-bg: #1A1A1A;
    --tea-green-bg: #18181F;
    --off-white-bg: #1A1A1A;
    --minty-cream-bg: #1A1A1A;
    --bg-baby-powder: #1A1A1A;
    --very-light-gray: #2A2A2A;
    --color-charleston-green: #EAEAEA;
    --pearl-aqua-bg: #181818;
    --border-pearl-aqua: #757373;
    --bg-milk: #1A1A1A;
    --gray-light: rgba(255, 255, 255, 0.8);
    --border-gainsboro: #353539;
    --light-blue: #13151B;
    --bg-azure: #191919;
    --bg-magnolia: #13151B;
    --floral-white: #131313;
    --very-light-purple: #353539;
    --pale-baby-blue: #252525;
    --light-pastel-blue-gray: #181818;
    --outer-space-color: #fff;
    --bg-chart-tooltip: #252525;
    --cosmic-latte: #252525;
    --color-red: #b43333;
    --ufo-green: #27cf5e;
    --sea-green: #209e49;
    --antique-white-bg: #252525;
    --sunset-blaze: #b46b39;
    --yellow-orange: #b28849;
    --pale-steel-blue: #252525;
    --dark-spring-green: #1a823c;
    --eerie-black-text: #fff;
    --pure-white: #252525;
    --pale-yellow: #252525;
    --mint-cream: #252525;
    --light-powder-blue: #252525;
    --icon-black:#EAEAEA;
    --ivory: #13151B;
    --dark-jungle-green: #EDEDED;
    --frosty-cyan: #252525;
    --light-bubbles:#191919;
    --grayish-white: #252525;
    --almost-black: #EDEDED;
    --light-silver: #353539;
    --light-sky-blue: #252525;
    --soft-pink: #3f3838;
    --bubbles: #3D3D42;
    --pastel-turquoise: #35a147;
    --pastel-sky-blue: #7991a1;
    --celadon: #353539;
    --icy-blue: #252525;
    --azure-mist: #252525;
    --pale-cyan: #252525;
    --beige: #252525;
    --greenish-white: #252525;
    --very-light-alice-blue:#353539;
    --dark-silver: #fff;
    --chart-center-text: #7A7E96;
    --aero-blue: #252525;
    --pale-sky-blue: #252525;
}

