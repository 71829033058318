@import '../color.module.scss';

.e_outline_btn {
    position: relative;
    outline: none;
    background-color: $pale-steel-blue;
    color: $primary-color;
    html[data-theme="dark"] & {
        color: $white;
        background-color: #252525;
        transition: ease-in-out 0.3s;
            &:hover {
                background-color: $charcoal-gray;
            }
    }
    &:hover {
        background: #a7c5d77c;
    }

    span {
        z-index: 2;
    }
}

.e_outline_btn[data-loading=true] {
    pointer-events: none!important;
    opacity: 0.6!important;
}
.e_disabled {
    pointer-events: none;
    opacity: 0.5;
   
}

// inactive btn style
.e_inactive_btn {
    @extend .e_outline_btn;
    pointer-events: none;
    opacity: 0.5;
    html[data-theme="dark"] & {
        color: #828282 !important;
    }
}

.loader_gif {
    width: 20px;
    height: 20px;
    margin-top: 3px;
    margin-left: 6px;
}