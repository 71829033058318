@import '../global.scss';

.e_fund_redeem {
    &::-webkit-scrollbar {
        display: none;
    }

    padding-right: 0px !important;
    .e_dialog{
        max-height: 90vh;
    }
    .e_body {
        max-height: 60vh;
        overflow-y: scroll;
        padding-right: 10px;
        &::-webkit-scrollbar {
            width: 0.175rem;
        }
    
        &::-webkit-scrollbar-thumb {
            border-radius: 0.25rem;
            background-color: $primary-color;
        }

        
    }

    .fund_avg_returns {
        background: linear-gradient(90deg, #E8FFF2 0%, rgba(219, 253, 234, 0.3) 50%, #E8FFF2 100%);
        position: relative;
        img {
            width: 1.5rem;
            height: 1.5rem;
        }
        html[data-theme=dark] & {
            background: linear-gradient(90deg, #1A3324 0%, rgba(26, 51, 36, 0.3) 50%, #1A3324 100%);
        }
    }

    .e_dialog {
        max-width: 39.75rem;
    }

    .e_content {
        border: none;
    }

    .e_close {
        top: 16px;
        right: 26px
    }

    .e_proceed_close {
        top: 24px;
        right: 24px;
    }

    .e_transition_center {
        left: 0;
        top: 50%;
        transform: translate(22px, -50%);
    }

    .e_transition_top {
        top: -0.7rem;
        left: 15px;
        padding-left: 0.375rem;
        padding-right: 0.375rem;
        margin: 0;
        transition: top 0.5s ease;
    }

    .e_rotate_left_90 {
        transform: rotate(-90deg);
        transition: transform 0.3s ease;
    }

    .e_rotate_right_90 {
        transform: rotate(90deg);
        transition: transform 0.3s ease;
    }

    .e_folio_dropdown {
        top: 7rem;
        box-shadow: 4px 4px 48px 0px rgba(7, 0, 124, 0.08);
    }

    .e_redeem_input_box {
        padding-left: 1.375rem !important;
        // padding-right: 13.5rem !important;

    }

    .select_bank_radio {
        width: 17.313rem;

        @media (max-width: 576px) {
            width: 100%;
        }
    }

    // payment screen
    .e_back {
        transform: rotate(-180deg);
        left: 24px;
        top: 24px;
    }

}

@media (max-width: 575px) {
    .e_fund_redeem {
        .e_content{
            max-height: 90vh;
        }
        .e_body {
            max-height: 50vh;
        }
    }
}