@import "../global.scss";


.e_accordian_item {
    border-bottom: none;
    overflow: auto;
    background-color: $light-sky-blue;
    html[data-theme="dark"] & {
        background-color: var(--card-bg-color-common);
    }
    .e_accordian_header {
        box-shadow: none;

        .e_accordian_button {
            padding: 16px;
            background-color: transparent;
            box-shadow: none;
            color: $black !important;
        }
    }

    .e_accordian_body {
        padding: 16px;
        padding-top: 0;

        .progress_status {
            width: max-content;

            .e_progress_line {
                height: 1px;
                width: 84%;
                top: 12px;
                left: 52px;
            }
        }

    }
}