@import "../global.scss";

.e_cart_card {
    box-shadow: 0px 4px 4px 0px rgba(50, 50, 71, 0.08);
}

.e_cart_edit_hover {
    top: -22px;
    left: -6px;
    padding: 2px 8px;
    box-shadow: 4px 4px 12px 0px rgba(175, 175, 175, 0.58);
    html[data-theme=dark] & {
         box-shadow: 0px 4px 4px 0px rgba(239, 236, 236, 0.078);
    }
}