@import "../global.scss";

.e_create_mandate {
    .e_dialog {
        max-width: 39.75rem;
    }

    .e_content {
        border-radius: 1.5rem;
    }
    .content_wrapper {
        max-height: 75vh;
        overflow: auto;
        &::-webkit-scrollbar {
            width: 0.175rem;
        }
    
        &::-webkit-scrollbar-thumb {
            border-radius: 0.25rem;
            background-color: $primary-color;
        }
    }
}

@media (max-width: 575px) {
    .e_create_mandate {
        // .e_dialog {
        //     height: 90%;
        // }
    
        .e_content {
            height: 95%;
            .content_wrapper{
                max-height: 90vh;
            }
        }
    }
}