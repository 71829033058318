@import "../global.scss";

.e_search_input {
    svg {
        right: 12px;
        margin: auto 0;
    }

    .e_input_wrapper {
        .e_input {
            caret-color: black;
            color: #4d4d4d;
            font-size: 16px;
            font-family: "Poppins-Medium";
            border-radius: 16px;
            border-width: 1px;
            border-style: solid;
            border-radius: 8px;
            background: #fff;
            width: 100%;
            padding: 14px 12px 14px;
            outline: none;
                html[data-theme=dark] & {
                    background: transparent;
                    border: 1px solid #353535;
                    color: #fff;
                    caret-color: #fff;
                }
        }

        label {
            top: 50%;
            transform: translateY(-50%);
            transition: ease 0.4s;
            color: $black;
            left: 1.5rem;
            background-color: var(--modal-bg-color);
        }

        .e_input label {
            -webkit-transition: all 0.2s;
            transition: all 0.2s;
        }

        .e_input {

            &:focus {
                &::placeholder {
                    color: #cbd5e1;
                }

                border-color: #1177bd;
            }
        }

        .e_input {
            &:focus~label,
            &:valid~label,
            &[readonly][value]:not([value=""])~label {
                top: 0;
                left: 1.5rem;
                color: #1177bd;
                html[data-theme=dark] & {
                    color: #fff;
                }
                // color: #1177bd;

            }
        }
    }

}

.e_search_input input::placeholder {
    color: $outer-space;
    font-size: 14px;
    font-family: "Poppins-Regular";
    line-height: 24px;
}

.e_error_input {
    input {
        border: 0.0625rem solid red !important;
        /* 1px converted to rem */
    }

    input::placeholder {
        color: red;
        font-size: 16px;
        font-family: "Poppins-Regular";
    }
}