@import '../global.scss';

.e_life_goals {
    background-color: $white;
    html[data-theme="dark"] & {
        background-color: $jet-black;;
        transition: background-color ease-in-out 0.4s;
            &:hover {
                background-color: var(--portfolio-breakdown-bg);
                outline: 0.0625rem solid $dusty-gray;
            }
    }
    &:hover {
        // outline: 0.0625rem solid $anti-flash-white;
        /* 1px converted to rem */
        box-shadow: 0px 0.3125rem 1rem 0px rgba(50, 50, 71, 0.08);
        /* 5px and 16px converted to rem */

        .e_icon {
            transition: .2s ease-in-out transform;
            transform: translate3d(.25em, 0, 0);
        }
    }

    .e_icon {
        right: 0.5rem;
        /* 8px converted to rem */
        top: 0.75rem;
        /* 12px converted to rem */
    }

    .e_bg_image {
        right: 0;
        bottom: 0;
    }
}

.e_invest_goals {
    html[data-theme="dark"] & {
        background-color: $deep_midnight;
        transition: background-color ease-in-out 0.4s;
        outline: 0.0625rem solid transparent;
            &:hover {
                background-color: var(--portfolio-breakdown-bg);
                outline: 0.0625rem solid $dusty-gray;
            }
    }
    &:hover {

        box-shadow: 0px 16px 16px 0px rgba(50, 50, 71, 0.06);
        /* 5px and 16px converted to rem */

        .e_icon_invest {
            transition: .2s ease-in-out transform;
            transform: translate3d(.25em, 0, 0);
        }

        .e_goal_title {
            span {
                color: $primary-color;

                &::after {
                    width: 100%;
                }
            }

            .e_fund_order_title_icon {
                /* Set the width of the icon to your desired size */
                opacity: 1;
                transform: translateX(0);
                /* Slide the icon in from the left */
            }
        }
    }

    .e_icon_invest {
        right: 1rem;
        /* 16px converted to rem */
        top: 1rem;
        /* 16px converted to rem */
    }

    .e_bg_image {
        right: 0;
        bottom: 0;
    }

    .e_goal_title {
        span {
            position: relative;
            z-index: 1;

            &::after {
                content: "";
                position: absolute;
                height: 1px;
                /* 1.5px converted to rem */
                width: 0;
                border-radius: 0.25rem;
                /* 4px converted to rem */
                background-color: $primary-color;
                transition: all 0.5s ease;
                bottom: 3px;
                /* 4px converted to rem */
                left: 0;
            }
        }

        .e_fund_order_title_icon {
            opacity: 0;
            /* Initially, set opacity to 0 */
            transform: translateX(-90%);
            /* Move the icon offscreen to the left */
            transition: opacity 0.3s ease, transform 0.3s ease;
        }
    }
}