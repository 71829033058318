/*
 *   File : global.css
 *   Author : https://evoqins.com
 *   Description : CSS file
 *   Integrations : NA
 *   Version : 1.0.0
*/

@charset "UTF-8";
@import "./color.module.scss";

#e-body-container {
    overflow-x: hidden !important;
    -ms-overflow-style: none;

    button,
    input {
        outline: none;
    }
}

body {
    font-size: 1rem;
    overflow-x: hidden  !important;
    background-color: $body-bg-color;
    color: $black;
}

.no-scrollbar {
    &::-webkit-scrollbar {
        display: none;
    }
}

.e-scroll-container {
    &::-webkit-scrollbar {
        width: 0.375rem;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0.25rem;
        background-color: $primary-color;
    }
}
/*----- Poppins font family ------*/

@font-face {
    font-family: "Poppins-Bold";
    src: url(../Assets/Fonts/Poppins/Poppins-Bold.ttf);
    //  font-weight: 700;
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url(../Assets/Fonts/Poppins/Poppins-SemiBold.ttf);
    //  font-weight: 600;
}

@font-face {
    font-family: "Poppins-Medium";
    src: url(../Assets/Fonts/Poppins/Poppins-Medium.ttf);
    //  font-weight: 500;
}

@font-face {
    font-family: "Poppins-Regular";
    src: url(../Assets/Fonts/Poppins/Poppins-Regular.ttf);
    //  font-weight: 400;
}

@font-face {
    font-family: "Poppins-Light";
    src: url(../Assets/Fonts/Poppins/Poppins-Light.ttf);
    //  font-weight: 300;
}

@font-face {
    font-family: "Poppins";
    src: url(../Assets/Fonts/Poppins/Poppins-Medium.ttf);
     font-weight: 500;
}

/*----- Inter font family ------*/
@font-face {
    font-family: "Inter-Extra-Bold";
    src: url(../Assets/Fonts/Inter/Inter-ExtraBold.ttf);
    //  font-weight: 800;
}

@font-face {
    font-family: "Inter-Bold";
    src: url(../Assets/Fonts/Inter/Inter-Bold.ttf);
    //  font-weight: 700;
}

@font-face {
    font-family: "Inter-SemiBold";
    src: url(../Assets/Fonts/Inter/Inter-SemiBold.ttf);
    //  font-weight: 600;
}

@font-face {
    font-family: "Inter-Medium";
    src: url(../Assets/Fonts/Inter/Inter-Medium.ttf);
    //  font-weight: 500;
}

@font-face {
    font-family: "Inter-Regular";
    src: url(../Assets/Fonts/Inter/Inter-Regular.ttf);
    //  font-weight: 400;
}

@font-face {
    font-family: "Inter-Light";
    src: url(../Assets/Fonts/Inter/Inter-Light.ttf);
    //  font-weight: 300;
}

// --- Font family ----

// Poppins
.e-poppins-light {
    font-family: "Poppins-Light";
}

.e-poppins-regular {
    font-family: "Poppins-Regular";
}

.e-poppins-medium {
    font-family: "Poppins-Medium";
}

.e-poppins-semi-bold {
    font-family: "Poppins-SemiBold";
}

.e-poppins-bold {
    font-family: "Poppins-Bold";
}

// Inter
.e-inter-light {
    font-family: "Inter-Light";
}

.e-inter-regular {
    font-family: "Inter-Regular";
}

.e-inter-medium {
    font-family: "Inter-Medium";
}

.e-inter-semi-bold {
    font-family: "Inter-SemiBold";
}

.e-inter-bold {
    font-family: "Inter-Bold";
}

.e-inter-extra-bold {
    font-family: "Inter-Extra-Bold";
}

/* --- Font variables sizes --- */
%font8 {
    font-size: 0.5rem;
}

%font10 {
    font-size: 0.625rem;
}

%font12 {
    font-size: 0.75rem;
}

%font14 {
    font-size: 0.875rem;
}

%font15 {
    font-size: 0.937rem;
}

%font16 {
    font-size: 1rem;
}

%font18 {
    font-size: 1.125rem;
}

%font20 {
    font-size: 1.25rem;
}

%font22 {
    font-size: 1.375rem;
}

%font24 {
    font-size: 1.5rem;
}

%font28 {
    font-size: 1.75rem;
}

%font30 {
    font-size: 1.875rem;
}

%font32 {
    font-size: 2rem;
}

%font36 {
    font-size: 2.25rem;
}

%font40 {
    font-size: 2.5rem;
}

%font52 {
    font-size: 3.25rem;
}

%font56 {
    font-size: 3.5rem;
}

// font sizes
.e-font-8 {
    @extend %font8;
}

.e-font-10 {
    @extend %font10;
}

.e-font-12, .e-font-12-sm-14 {
    @extend %font12;
}

.e-font-12-sm-10 {
    @extend %font12;
}

.e-font-14 {
    @extend %font14;
}

.e-font-14-sm-12 {
    @extend %font14;
}

.e-font-15 {
    @extend %font15;
}

.e-font-16 {
    @extend %font16;
}

.e-font-16-xl-14 {
    @extend %font16;
}

.e-font-16-sm-14 {
    @extend %font16;
}

.e-font-16-sm-12 {
    @extend %font16;
}

.e-font-16-sm-10 {
    @extend %font16;
}

.e-font-18 {
    @extend %font18;
}

.e-font-18-sm-16 {
    @extend %font18;
}

.e-font-18-sm-14 {
    @extend %font18;
}

.e-font-18-sm-12 {
    @extend %font18;
}

.e-font-20 {
    @extend %font20;
}

.e-font-20-sm-18 {
    @extend %font20;
}

.e-font-22 {
    @extend %font22;
}

.e-font-22-sm-18 {
    @extend %font22;
}

.e-font-24 {
    @extend %font24;
}
.e-font-24-sm-14 {
    @extend %font24; 
}

.e-font-24-sm-18 {
    @extend %font24;
}

.e-font-sm-24-20 {
    @extend %font24;
}

.e-font-28 {
    @extend %font28;
}

.e-font-30 {
    @extend %font30;
}

.e-font-32 {
    @extend %font32;
}

.e-font-32-sm-24 {
    @extend %font32;
}

.e-font-32-sm-18 {
    @extend %font32;
}

.e-font-36 {
    @extend %font36;
}

.e-font-40 {
    @extend %font40;
}

.e-font-52 {
    @extend %font52;
}

.e-font-56 {
    @extend %font56;
}

.e-font-sm-16-14 {
    @extend %font16;
}

// font-colors
.color-primary-color {
    color: $primary-color;
}
.color-primary-color-theme-white {
    color: $primary-color;
    html[data-theme=dark] & {
        color: $white;
    }
}

.color-granite-gray {
    color: $granite-gray;
}

.color-white {
    color: $white;
}

.color-black {
    color: $black;
}

.color-black-all-theme {
    color: #222b38;
}

.color-old-silver {
    color: $old-silver;
}

.color-eerie-black {
    color: var(--eerie-black-text);
}

.color-sea-green {
    color: $sea-green;
}

.color-dark-liver {
    color: $dark-liver;
}

.color-jett-black {
    color: $jett-black;
    html[data-theme="dark"] &{
        color: $white;
    }
}

.color-jett-black-dark {
    color: $jet-black;
    html[data-theme="dark"] &{
        color: $white;
    }
}

.color-ufo-green {
    color: var(--ufo-green);
}

.color-davys-grey {
    color: var(--color-davys-grey);
}

.color-charcoal {
    color: $charcoal;
}

.color-cadet-grey {
    color: var(--color-cadet-grey);
}

.color-outer-space {
    color: var(--color-outer-space);
}

.color-gray {
    color: $gray;
}

.color-gray-light {
    color: var(--gray-light);
}

.color-eerie-black {
    color: var(--eerie-black-text);
}

.color-rick-black {
    color: $rich-black;
}

.color-red {
    color: var(--color-red);
}

.color-bright-red {
    color: $bright-red;
}

.color-charleston-green {
    color: var(--color-charleston-green);
}

.color-light-silver {
    color: $light-silver;
}

.color-chinese-white {
    color: $chinese-white;
}

.color-orange-soda {
    color: $orange-soda;
}

.color-dark-silver {
    color: $dark-silver;
}

.color-may-green {
    color: $may-green;
}

.color-green-crayola {
    color: $green-crayola;
}

.color-pale-white {
    color: $pale-white;
}

.color-deep-red {
    color: $deep-red;
}

.color-off-white {
    color: $off-white;
}

.color-yellow-orange {
    color: $yellow-orange;
}

.color-lust {
    color: $lust;
}

.color-dark-jungle-green {
    color: var(--dark-jungle-green);
}

.color-dark-spring-green {
    color: $dark-spring-green;
}

.color-kenyan-copper {
    color: $kenyan-copper;
}

.color-medium-carmine {
    color: $medium-carmine;
}

.color-azure-blue {
    color: $azure-blue;
}

.color-polished-pine {
    color: $polished-pine;
}

.color-ocean-blue {
    color: $ocean-blue;
}

.color-light-cyan {
    color: $light-cyan;
}

.color-onyx {
    color: $onyx;
}

.color-onyx-dark {
    color: $onyx;
    html[data-theme="dark"] & {
        color: $white;
    } 
}

.color-steel-gray {
    color: $steel-gray;
}

.color-very-dark-slate-blue {
    color: $very-dark-slate-blue;
}

.color-mango-tango {
    color: $mango-tango;
}

.color-sunset-blaze {
    color: $sunset-blaze;
}

.color-vivid-orange {
    color: $vivid-orange;
}

.color-icon-black {
    color: var(--icon-black);
}

.color-red-orange {
    color: $red-orange;
}

.color-almost-black {
    color: var(--almost-black);
}

// background color
.bg-sidebar {
    background-color: $side-bar-bg;
}
.bg-header {
    background-color: $header-bg;
}
.bg-input {
    background-color: $input-bg;
}
.bg-footer {
    background-color: $footer-bg;
}
.bg-portfolio-summary-card {
    background-color: $portfolio-summary-card-bg;
}
.bg-cards {
    background-color: var(--card-bg-color-common);
}

.bg-investment-breakdown {
    background-color: var(--invest-breakdown-bg);
}
.bg-portfolio-breakdown {
    background-color: var(--portfolio-breakdown-bg);
}
.bg-quick-invest-card {
    background-color: var(--quick-invest-card-bg);
}
.bg-news-card {
    background-color: var(--news-card-bg);
}
.bg-sip-calculator {
    background-color: var(--sip-calculator-bg);
}
.bg-calculator-value-card {
    background-color: var(--calculator-value-card);
}
.bg-loader {
    background-color: $body-bg-color;
}
.bg-fund-search-dropdown {
    background-color: var(--fund-search-dropdown-bg);
}
.bg-fund-compare-card {
    background-color: var(--fund-compare-card-bg);
}
.bg-modal {
    background-color: var(--modal-bg-color);
}
.bg-ocean-boat-blue {
    background-color: $primary-color;
}
.bg-charcoal-gray {
    background-color: $charcoal-gray;
}
.bg-light-red {
    background-color: $light-red;
}

.bg-frosty-cyan {
    background-color: var(--frosty-cyan);
}

.bg-light-cyan {
    background-color: $light-cyan;
}

.bg-white-smoke {
    background-color: $white-smoke;
}

.bg-white {
    background-color: $white;
}

.bg-ghost-white {
    background-color: $ghost-white;
    html[data-theme=dark] & {
        background-color: $gunmetal-gray
    }
}

.bg-floral-white {
    background-color: $floral-white;
}

.bg-cornsilk {
    background-color: $cornsilk;
}

.bg-alice-blue {
    background-color: $alice-blue;
}

.bg-pearl-aqua {
    background-color: var(--pearl-aqua-bg);
}
.bg-pearl-aqua-all-theme {
    background-color: $pearl-aqua;
}

.bg-magnolia {
    background-color: var(--bg-magnolia);
}

.bg-body-color {
    background-color: var(--body-bg-color);
}

.bg-lotion {
    background-color: $lotion;
}

.bg-sapphire {
    background-color: $sapphire;
}

.bg-true-blue {
    background-color: $true-blue;
}

.bg-bright-gray {
    background-color: $bright-gray;
}

.bg-mint-cream {
    background-color: var(--mint-cream);
}

.bg-black {
    background-color: $black !important;
}

.bg-chinese-white {
    background: $chinese-white !important;
}

.bg-pastel-green {
    background-color: $pastel-green;
}

.bg-light-salmon {
    background-color: $light-salmon;
}
.bg-sea-green {
    background-color: $sea-green;
}

.bg-prime-gradient {
    background: linear-gradient(180deg, #0575e6 0%, #093ab8 100%);
}

.bg-honey-dew {
    background-color: $honey-dew;
}

.bg-pale-steel-blue {
    background-color: var(--pale-steel-blue);
}

.bg-nyanza {
    background-color: $nyanza;
}

.bg-greenish-yellow {
    background-color: $greenish-yellow;
}

.bg-may-green {
    background-color: $may-green;
}

.bg-light-blue {
    background-color: var(--light-blue);
}

.bg-light-gray {
    background-color: $light-gray;
}

.bg-light-silver {
    background-color: var(--light-silver);
}

.bg-minty {
    background-color: $minty;
}

.bg-cream {
    background-color: $cream;
}

.bg-light-yellow {
    background-color: $light-yellow;
}

.bg-pale-green {
    background-color: $pale-green;
}

.bg-pale-white {
    background-color: $pale-white;
}

.bg-mint-green {
    background-color: var(--bg-mint-green);
}

.bg-blue-gray {
    background-color: $blue-gray;
}

.bg-silver {
    background-color: $silver;
}

.bg-pale-mint-green {
    background-color: var(--pale-mint-green-bg);
}

.bg-tea-green {
    background-color: var(--tea-green-bg);
}

.bg-off-white {
    background-color: var(--off-white-bg);
}

.bg-minty-cream {
    background-color: var(--minty-cream-bg);
}

.bg-baby-powder {
    background-color: var(--bg-baby-powder);
}

.bg-seaform-green {
    background-color: $seaform-green;
}

.bg-jordy-blue {
    background-color: $jordy-blue;
}

.bg-baby-blue {
    background-color: $baby-blue;
}

.bg-light-greenish-white {
    background-color: $light-greenish-white;
}

.bg-light-green {
    background-color: $light-green;
}

.bg-jet-black {
    background-color: $jett-black;
}

.bg-vivid-cerulean {
    background-color: $vivid-cerulean;
}

.bg-bubble-light {
    background-color: var(--light-bubbles);
}

.bg-baby-blue-eye {
    background-color: $baby-blue-eye;
}

.bg-bubbles {
    background-color: var(--bubbles);
}

.bg-bubbles-all-theme  {
   background-color: $bubbles; 
}

.bg-icy-blue {
    background-color: var(--icy-blue);
}

.bg-platinum {
    background-color: $platinum;
}

.bg-light-sky-blue {
    background-color: var(--light-sky-blue);
}

.bg-very-light-malachite-green {
    background-color: $very-light-malachite-green;
}

.bg-milk {
    background-color: var(--bg-milk);
}

.bg-azure {
    background-color: var(--bg-azure);
}

.bg-baby-power {
    background-color: $baby-power;
}

.bg-floral-white {
    background-color: $floral-white;
}

.bg-cosmic-latte {
    background-color: var(--cosmic-latte);
}

.bg-light-grayish-white {
    background-color: $light-grayish-white;
}

.bg-light-pastel-blue-gray {
    background-color: var(--light-pastel-blue-gray);
}

.bg-pastel-turquoise {
    background-color: var(--pastel-turquoise);
}

.bg-pastel-sky-blue {
    background-color: var(--pastel-sky-blue);
}

.bg-antique-white {
    background-color: var(--antique-white-bg);
}

.bg-chinese-orange {
    background-color: $chinese-orange;
}

.bg-pale-yellow {
    background-color: var(--pale-yellow);
}

.bg-greenish-white {
    background-color: var(--greenish-white);
}

.bg-pale-baby-blue {
    background-color: var(--pale-baby-blue);
}

.bg-light-malachite-green {
    background-color: $light-malachite-green;
}

.bg-pale-purple {
    background-color: $pale-purple;
}

.bg-cultured {
    background-color: $cultured;
    html[data-theme=dark] & {
        background-color: $elegant_onyx;
    }
}

.bg-pure-white {
    background-color: var(--pure-white);
}

.bg-soft-green {
    background-color: $soft-green;
}

.bg-pale-cream {
    background-color: $pale-cream;
}

.bg-off-white-color {
    background-color: $off-white-color;
}

.bg-lavender {
    background-color: $lavender;
}

.bg-pale-lavender {
    background-color: $pale-lavender;
}

.bg-pale-turquoise {
    background-color: $pale-turquoise;
}

.bg-pale-blueish {
    background-color: $pale-blueish;
}

.bg-pale-mint {
    background-color: $pale-mint;
}

.bg-grayish-white {
    background-color: var(--grayish-white);
}

.bg-azure-mist {
    background-color: var(--azure-mist);
}

.bg-beige {
    background-color: var(--beige);
}

.bg-sliver-gray {
    background-color: $silver-gray;
}

.bg-very-light-alice-blue {
    background-color: var(--very-light-alice-blue);
}

.bg-ivory {
    background-color: var(--ivory);
}

.bg-faint-pink {
    background-color: $faint-pink;
}

.bg-bg-jade {
    background-color: $pale-jade;
}

.bg-pale-lime {
    background-color: $pale-lime;
}

.bg-vivid-lime {
    background-color: $vivid-lime;
}

.bg-blonde {
    background-color: $blonde;
}

.bg-sun-lit-yellow {
    background-color: $sun-lit-yellow;
}

.bg-sunflower {
    background-color: $sunflower;
}

.bg-canary-yellow {
    background-color: $canary-yellow;
}

.bg-bisque {
    background-color: $bisque;
}

.bg-peachy-pink {
    background-color: $peachy-pink;
}

.bg-apricoat {
    background-color: $apricoat;
}

.bg-lemon-chiffon {
    background-color: $lemon-chiffon;
}

.bg-celadon {
    background-color: var(--celadon);
}

.bg-very-light-gray-md {
    background-color: var(--very-light-gray);
}

.bg-aero-blue {
    background-color: var(--aero-blue);
}

.bg-pale-cyan {
    background-color: var(--pale-cyan);
}

.bg-pale-aqua {
    background-color: $pale-aqua;
}

.bg-powder-blue {
    background-color: $powder-blue;
}

.bg-dashboard-goal-list {
    background-color: var(--dashboard-goal-list-bg);
}

.bg-pale-orange {
    background-color: $pale-orange;
}

.bg-soft-pink {
    background-color: var(--soft-pink);
}

.bg-mint-ice {
    background-color: $mint-ice;
}

.bg-light-sky {
    background-color: $light-sky;
}

.bg-alabaster {
    background-color: var(--bg-alabaster);
}

.bg-sip-detail-value-card {
    background-color: var(--bg-sip-detail-value-card);
}

.bg-profile-summary-card {
    background-color: var(--bg-profile-summary-card);
}

.bg-ice-blue {
    background-color: var(--bg-ice-blue);
}

.bg-light-powder-blue {
    background-color: var(--light-powder-blue);
}

.bg-pale-sky-blue {
    background-color:var(--pale-sky-blue);
}

// height
.h-2px {
    height: 0.125rem;
}

.h-10px {
    height: 0.625rem;
}

.h-16px {
    height: 1rem;
}

.h-20px {
    height: 1.25rem;
}

.h-24px {
    height: 1.5rem;
}

.h-40px {
    height: 2.5rem;
}

.h-72px {
    height: 4.5rem;
}

.h-100vh {
    height: 100vh;
}

.h-sm-100vh {
    height: 100vh;
}

.h-100px {
    height: 100px;
}

.h-64px, .h-64px-sm-48 {
    height: 4rem;
}

.h-68px {
    height: 4.25rem;
}

.h-56px {
    height: 3.5rem;
}

.h-70px {
    height: 4.375rem;
}

.h-84px {
    height: 5.25rem;
}

.h-90 {
    height: 90%;
}

.h-22px {
    height: 1.375rem;
}

.h-24px {
    height: 1.5rem;
}

.h-32px {
    height: 2rem;
}

.h-39px {
    height: 39px;
}

.h-95 {
    height: 95%;
}

.h-50px {
    height: 50px;
}

.h-80px {
    height: 80px;
}

.h-150px {
    height: 150px;
}

.h-160px {
    height: 160px;
}


.h-200px {
    height: 200px;
}

.h-214px {
    height: 13.375rem;
}

.h-250px {
    height: 250px;
}

.h-300px {
    height: 300px;
}

.h-400px {
    height: 25rem;
}


.h-70vh {
    height: 70vh;
}

.h-80vh {
    height: 80vh;
}

.h-80 {
    height: 80%;
}

.h-fit-content {
    height: fit-content;
}

.h-max-content {
    height: max-content;
}

.max-h-25 {
    max-height: 25.5rem;
}

.max-height-250px {
    max-height: 15.625rem;
}

// width
.w-1px {
    width: 0.0625rem;
}

.w-16px {
    width: 1rem;
}

.w-24px {
    width: 1.5rem;
}

.w-32px {
    width: 2rem;
}

.w-35px {
    width: 2.1875rem;
}

.w-40px {
    width: 2.5rem;
}

.w-50px {
    width: 3.125rem;
}

.w-55px {
    width: 3.437rem;
}

.w-64px {
    width: 4rem;
}

.w-80px {
    width: 5rem;
}

.w-85 {
    width: 85%!important;
}

.w-105px {
    width: 105px;
}

.w-125 {
    width: 7.8125rem !important;
}

.w-150 {
    width: 150px !important;
}

.w-170 {
    width: 170px !important;
}

.w-190px {
    width: 11.875rem!important;
}

.w-200px {
    width: 12.5rem!important;
}

.w-208px {
    width: 13rem!important;
}

.w-213px {
    width: 13.3125rem;
}

.w-60 {
    width: 60% !important;
}

.w-66px {
    width: 4.125rem;
}

.w-72px {
    width: 4.5rem;
}

.w-80 {
    width: 80% !important;
}

.w-90 {
    width: 90%;
}

.w-300px {
    width: 18.75rem;
}

.w-unset {
    width: unset !important;
}

.w-fit-content {
    width: fit-content;
}

.w-max-content {
    width: max-content;
}

.w-sm-190px {
    width: 11.875rem!important;
}

.w-sm-150 {
    width: 150px !important;
}

.w-sm-170 {
    width: 170px !important;
}

.e_width_386 {
    width: 24.125rem !important;
}

.min-width-50 {
    min-width: 3.125rem;
}


.min-width-60 {
    min-width: 3.75rem;
}

.min-width-60-sm-50 {
    min-width: 3.75rem;
}

.max-width-300 {
    max-width: 300px;
}

.min-height-130px-lg{
    min-height: 130px;
}

.min-height-142px-sm-120 {
    min-height: 142px;
}


// min height
.min-height-144px {
    min-height: 9rem;
}

.min-height-144px-sm-8rem {
    min-height: 9rem;
}

.min-height-255px {
    min-height: 14.062rem;
}
.min-height-300px {
    min-height: 18.75rem;
}

.min-height-345px-sm-224{
    min-height: 345px;
}

.min-height-400px {
    min-height: 25rem;
}

// translate
.translate-y-15 {
    transform: translateY(-15px);
}

.translate-y-0 {
    transform: translateY(0%);
    transition: ease 0.4s;
    top: 0;
}

div[data-disabled="true"] {
    opacity: 0.5;
    pointer-events: none !important;
}

.no-events {
    pointer-events: none;
}

.text-overflow-ellipsis {
    text-overflow: ellipsis;
}

.line-clamp-1 {
    -webkit-line-clamp: 1;
}

/*--- Text style declaration ---*/
%subText10 {
    @extend %font10;
    color: $black;
    letter-spacing: 0.02988rem;
    font-weight: 500;
}

%subText12 {
    @extend %font12;
    color: $black;
    letter-spacing: 0.02988rem;
    font-weight: 400;
}

%subText14 {
    @extend %font14;
    color: $black;
    letter-spacing: 0.02988rem;
    font-weight: 500;
}

%subText16 {
    @extend %font16;
    color: $black;
    letter-spacing: 0.02988rem;
    font-weight: 400;
}

%p14 {
    @extend %font14;
    color: $black;
    line-height: 1.375rem;
    font-weight: 400;
}

%p16 {
    @extend %font16;
    color: $black;
    line-height: 1.375rem;
    letter-spacing: 0.01992rem;
    font-weight: 400;
}

%p24 {
    @extend %font24;
    color: $black;
    line-height: 1.875rem;
    font-weight: 400;
}

%h6 {
    @extend %font16;
    color: $black;
    line-height: 1.375rem;
    letter-spacing: 0.01992rem;
    font-weight: 700;
}

%h5 {
    @extend %font18;
    color: $black;
    line-height: 1.438rem;
    letter-spacing: 0.01992rem;
    font-weight: 700;
}

%h4 {
    @extend %font24;
    color: $black;
    line-height: 1.875rem;
    font-weight: 600;
}

%h3 {
    @extend %font28;
    color: $black;
    line-height: 2.188rem;
    font-weight: 500;
}

%h2 {
    @extend %font36;
    color: $black;
    line-height: 2.813rem;
    font-weight: 700;
}

%h1 {
    @extend %font56;
    color: $black;
    line-height: 4.438rem;
    font-weight: 500;
}

/* text property */
.text-normal {
    text-transform: unset !important;
}

.text-decoration-underline {
    text-decoration: underline;
}

.text-decoration-none {
    text-decoration: none;
}

.text-underline-offset-4 {
    text-underline-offset: 4px;
}
.word-wrap {
    word-wrap: break-word;
}

.word-break-all {
    word-break: break-all;
}
.text-transform-none {
    text-transform: none;
}

.text-transform-capitalize {
    text-transform: capitalize;
}

/* lineheight */
.line-height-normal {
    line-height: normal;
}

.line-height-0px {
    line-height: 0rem;
}

.line-height-8px {
    line-height: 0.5rem;
}

.line-height-10px {
    line-height: 0.625rem;
}

.line-height-12px {
    line-height: 0.75rem;
}

.line-height-14px {
    line-height: 0.875rem;
}

.line-height-sm-14px-18px {
    line-height: 0.875rem;
}

.line-height-sm-14px-20px {
    line-height: 0.875rem;
}

.line-height-16px {
    line-height: 1rem;
}

.line-height-16px-sm-24px {
    line-height: 1rem;
}

.line-height-18px {
    line-height: 1.125rem;
}

.line-height-20px {
    line-height: 1.25rem;
}

.line-height-sm-20px-24px {
    line-height: 1.25rem;
}

.line-height-22px {
    line-height: 1.375rem !important;
}

.line-height-22px-sm-26px {
    line-height: 1.375rem;
}

.line-height-24px {
    line-height: 1.5rem;
}

.line-height-26px {
    line-height: 1.625rem;
}

.line-height-28px {
    line-height: 1.75rem;
}

.line-height-30px {
    line-height: 1.875rem;
}

.line-height-32px {
    line-height: 2rem;
}

.line-height-34px {
    line-height: 2.125rem;
}

.line-height-34px-sm-24px{
    line-height: 2.125rem;
}

.line-height-36px {
    line-height: 2.25rem;
}

.line-height-38px {
    line-height: 2.375rem;
}

.line-height-40px {
    line-height: 2.5rem;
}

.line-height-42px {
    line-height: 2.625rem;
}

.line-height-44px {
    line-height: 2.75rem;
}

.line-height-46px {
    line-height: 2.875rem;
}

.line-height-48px {
    line-height: 3rem;
}

.line-height-48px-sm-32px {
    line-height: 3rem;
}

.line-height-50px {
    line-height: 3.125rem;
}

.line-height-56px {
    line-height: 3.5rem;
}

.line-height-60px {
    line-height: 3.75rem;
}

.line-height-62px {
    line-height: 3.875rem;
}

.line-height-70px {
    line-height: 4.375rem;
}

.line-height-72px {
    line-height: 4.5rem;
}

.line-height-160 {
    line-height: 160%;
}



/* border radius */
.no-border {
    border: 0;
}

.border-radius-1px {
    border-radius: 0.063rem;
}

.border-radius-2px {
    border-radius: 0.125rem;
}

.border-radius-4px {
    border-radius: 0.25rem;
}

.border-radius-6px {
    border-radius: 0.375rem;
}

.border-radius-8px {
    border-radius: 0.5rem;
}

.border-radius-10px {
    border-radius: 0.625rem;
}

.border-radius-12px {
    border-radius: 0.75rem !important;
}

.border-radius-16px {
    border-radius: 1rem;
}

.border-radius-20px {
    border-radius: 1.25rem;
}

.border-radius-24px {
    border-radius: 1.5rem;
}

.border-radius-28px {
    border-radius: 1.75rem;
}

.border-radius-30px {
    border-radius: 1.875rem;
}

.border-radius-30px-sm-0 {
    border-radius: 1.875rem;
}

.border-radius-32px {
    border-radius: 2rem;
}

.border-radius-54px {
    border-radius: 3.375rem;
}

.border-radius-64px {
    border-radius: 4rem;
}

.border-radius-100 {
    border-radius: 100%;
}

// border-radius-tr-br
.border-tbr-radius-12px {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}

.border-radius-right-16px {
    border-radius: 0 16px 0 0;
}

.border-radius-8-0 {
    border-radius: 8px 8px 0px 0px;
}

.border-radius-0-8 {
    border-radius: 0px 0px 8px 8px;
}

.border-gap {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%231177BD' stroke-width='1' stroke-dasharray='6%2c 8'  stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

// --- padding-all ---
.padding-2px-all {
    padding: 0.125rem;
}

.padding-4px-all {
    padding: 0.25rem;
}

.padding-6px-all {
    padding: 0.375rem;
}

.padding-12px-all {
    padding: 0.75rem;
}

.padding-14px-all {
    padding: 0.875rem;
}

.padding-16px-all {
    padding: 1rem;
}

.padding-18px-all {
    padding: 1.125rem;
}

.padding-24px-all, .padding-24px-all-sm-px-0-py-16 {
    padding: 1.5rem;
}

.padding-26px-all {
    padding: 1.625rem;
}

.padding-32px-all {
    padding: 2rem;
}

.padding-32px-all-sm-16 {
    padding: 2rem;
}

.padding-40px-all {
    padding: 2.5rem;
}

// padding-tb
.padding-0-tb {
    padding-top: 0;
    padding-bottom: 0;
}

.padding-2px-tb {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}

.padding-5px-tb {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
}

.padding-6px-tb {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}

.padding-8px-tb {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.padding-10px-tb {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}

.padding-12px-tb {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.padding-12px-tb-sm-8 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.padding-14px-tb {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
}

.padding-16px-tb {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.padding-18px-tb {
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
}

.padding-22px-tb {
    padding-top: 1.375rem;
    padding-top: 1.375rem;
}

.padding-24px-tb {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.padding-32px-tb {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.padding-34px-tb {
    padding-top: 2.125rem;
    padding-bottom: 2.125rem;
}

.padding-40px-tb {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.padding-42px-tb {
    padding-top: 2.625rem;
    padding-bottom: 2.625rem;
}

.padding-50px-tb {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
}

// padding-lr
.padding-4px-lr {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.padding-6px-lr {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
}

.padding-10px-lr {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
}

.padding-12px-lr {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.padding-13px-lr {
    padding-left: 0.8125rem;
    padding-right: 0.8125rem;
}

.padding-14px-lr {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
}

.padding-16px-lr {
    padding-left: 1rem;
    padding-right: 1rem;
}

.padding-18px-lr {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
}

.padding-20px-lr {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.padding-22px-lr {
    padding-left: 1.375rem;
    padding-right: 1.375rem;
}

.padding-24px-lr {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.padding-26px-lr {
    padding-left: 1.625rem;
    padding-right: 1.625rem;
}

.padding-28px-lr {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
}

.padding-30-lr {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
}

.padding-32px-lr {
    padding-left: 2rem;
    padding-right: 2rem;
}

.padding-36px-lr {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
}

.padding-40px-lr {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.padding-50px-lr {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
}

.padding-56px-lr {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
}

.padding-59px-lr {
    padding-left: 3.6875rem;
    padding-right: 3.6875rem;
}

.padding-60px-lr {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
}

.padding-64px-lr, .padding-64px-lr-md {
    padding-left: 4rem;
    padding-right: 4rem;
}


.padding-70px-lr {
    padding-left: 4.375rem;
    padding-right: 4.375rem;
}

.padding-72px-lr {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
}

.padding-140px-lr {
    padding-left: 8.75rem;
    padding-right: 8.75rem;
}

.padding-150px-lr {
    padding-left: 9.375rem;
    padding-right: 9.375rem;
}

.padding-160px-lr {
    padding-left: 10rem;
    padding-right: 10rem;
}

// padding-left

.padding-0-left {
    padding-left: 0;
}

.padding-12px-left {
    padding-left: 0.75rem;
}

.padding-26px-left {
    padding-left: 1.625rem !important;
}

.padding-28px-left {
    padding-left: 1.75rem !important;
}

.padding-30px-left {
    padding-left: 1.875rem;
}

.padding-30px-left-lg {
    padding-left: 1.875rem;
}

.padding-32px-left {
    padding-left: 2rem;
}

.padding-32px-left-sm-8 {
    padding-left: 2rem;
}

.padding-32px-left-sm-16 {
    padding-left: 2rem;
}

.padding-36px-left {
    padding-left: 2.25rem;
}

.padding-40px-left {
    padding-left: 2.5rem;
}

.padding-40px-left-sm-16 {
    padding-left: 2.5rem;
}

.padding-54px-left {
    padding-left: 3.25rem;
}

.padding-120px-left {
    padding-left: 7.5rem;
}

// padding-right
.padding-22px-right {
    padding-right: 1.375rem;
}

.padding-24px-right {
    padding-right: 1.5rem;
}

.padding-30px-right {
    padding-right: 1.875rem;
}

.padding-32px-right {
    padding-right: 2rem;
}

.padding-32px-right-sm-16 {
    padding-right: 2rem;
}


.padding-36px-right {
    padding-right: 2.25rem;
}

.padding-40px-right {
    padding-right: 2.5rem;
}

.padding-62px-right {
    padding-right: 3.875rem;
}

.padding-62px-right-mob {
    padding-right: 3.875rem;
}

.padding-72px-right {
    padding-right: 4.5rem;
}

.padding-100px-right {
    padding-right: 6.25rem;
}

.padding-100px-right-sm-0 {
    padding-right: 6.25rem;
}


// border
.border-all {
    border: 1px solid;
    html[data-theme=dark] & {
        border-color: $gunmetal-gray;
    }
}
.border-all-1px {
    border: 1px solid;
}

.border-all-dashed {
    border: 1px dashed;
}

// border-style

.border-solid {
    border-style: solid;
}

// border-right
.border-right-sm {
    border-right: 1px solid;
}
.border-right-1px, .border-right-1px-md {
    border-right: 1px solid $bright-gray;
    html[data-theme=dark] & {
        border-right: 1px solid $gunmetal-gray;
    }
}

.border-lg-right-1px {
    border-right: 1px solid $bright-gray;
    html[data-theme=dark] & {
        border-right: 1px solid $gunmetal-gray;
    }
}

.border-right-1px-lavender-blue {
    border-right: 1px solid $lavender-blue;
    html[data-theme=dark] & {
        border-right: 1px solid $gunmetal-gray;
    }
}

.border-right-1px-light-silver {
    border-right: 1px solid $light-silver;
}

.border-right-2px {
    border-right: 2px solid $lavender-web;
    html[data-theme=dark] & {
        border-right: 2px solid $gunmetal-gray;
    }
}

// border-left

.border-left-1px-lights-silver {
    border-left: 1px solid $light-silver;
}

.border-left-2px {
    border-left: 2px solid $pearl-aqua;
}

// border-width
.border-width-2px {
    border-width: 2px !important;
}

// border-top
.border-top-1px {
    border-top: 1px solid;
}

// border-bottom
.border-bottom-1px {
    border-bottom: 1px solid;
}

.border-bottom-2px {
    border-bottom: 2px solid;
}

/* border color */
.border-primary-color {
    border-color: $primary-color;
}

.border-platinum {
    border-color: $platinum;
}

.border-bright-gray {
    border-color: var(--border-bright-gray);
}
.border-bottom {
    html[data-theme=dark] & {
        border-color: #353535 !important;
    }
}
.border-anti-flash-white {
    border-color: var(--border-anti-flash-white);
}

.border-chinese-silver {
    border-color: $chinese-silver;
}

.border-lavender-web {
    border-color: $lavender-web;
}

.border-azureish-white {
    border-color: $azureish-white;
}

.border-light-silver {
    border-color: $light-silver;
    html[data-theme=dark] & {
        border-color: $gunmetal-gray;
    }
}

.border-may-green {
    border-color: $may-green;
}

.border-gray {
    border-color: $gray;
}

.border-transparent {
    border-color: transparent;
}

.border-sea-green {
    border-color: $sea-green;
}

.border-gainsboro {
    border-color: var(--border-gainsboro);
}

.border-nyanza {
    border-color: $nyanza;
}

.border-cream-green {
    border-color: $cream_green;
        html[data-theme="dark"] & {
            border-color: $gunmetal-gray;
        }
}

.border-true-blue {
    border-color: $true-blue;
}

.border-columbia-blue {
    border-color: $columbia-blue;
}

.border-pearl-aqua {
    border-color: var(--border-pearl-aqua);
}

.border-dark-spring-green {
    border-color: $dark-spring-green;
    html[data-theme=dark] & {
        border-color: $gunmetal-gray;
    }
}

.border-azure-blue {
    border-color: $azure-blue;
}

.border-red {
    border-color: $red;
}

.border-lavender-blue {
    border-color: $lavender-blue;
    html[data-theme=dark] & {
        border-color: $gunmetal-gray;
    }
}

.border-polished-pine {
    border-color: $polished-pine;
}

.border-very-light-purple {
    border-color: var(--very-light-purple);
}

.border-light-green {
    border-color: $light-green;
}

.border-shade-light-blue {
    border-color: $shade-light-blue;
    html[data-theme=dark] & {
        border-color: $gunmetal-gray;
    }
}

.border-cool-gray {
    border-color: $cool-gray;
    html[data-theme=dark] & {
        border-color: $gunmetal-gray;
    }
}

.border-old-silver {
    border-color: $old-silver;
}
// gap
.gap-6px {
    gap: 0.375rem;
}

.gap-8px {
    gap: 0.5rem;
}

.gap-10px {
    gap: 0.625rem;
}

.gap-12px {
    gap: 0.75rem;
}

.gap-12px-sm-8 {
    gap: 0.75rem;
}

.gap-14px {
    gap: 0.875rem;
}

.gap-16px {
    gap: 1rem;
}

.gap-18px {
    gap: 1.125rem;
}

.gap-20px {
    gap: 1.25rem;
}

.gap-22px {
    gap: 1.375rem;
}

.gap-24px {
    gap: 1.5rem;
}

.gap-24px-sm-16 {
    gap: 1.5rem;
}

.gap-24px-sm-12, .gap-24px-sm-8 {
    gap: 1.5rem;
}
.gap-28px {
    gap: 1.75rem;
}

.gap-32px {
    gap: 2rem;
}

.gap-32px-sm-16 {
    gap: 2rem;
}

.gap-34px {
    gap: 2.125rem;
}

.gap-36px {
    gap: 2.25rem;
}

.gap-40 {
    gap: 2.5rem;
}

.gap-100px {
    gap: 6.25rem;
}

.gap-sm-12px-8px  {
    gap: 0.75rem;
}

.gap-sm-14px-8px  {
    gap: 0.875rem;
}

.gap-sm-16px-8px {
    gap: 1rem;
}

.gap-sm-16px-0px {
    gap: 1rem;
}


.gap-sm-24px-8px {
    gap: 1.5rem;
}

.gap-sm-24px-16px {
    gap: 1rem;
}

.gap-sm-36px-16px {
    gap: 2.25rem;
}

.row-gap-8px {
    row-gap: 0.5rem;
}

.row-gap-16px {
    row-gap: 1rem !important;
}

// padding-bottom
.padding-12px-bottom {
    padding-bottom: 0.75rem;
}

.padding-14px-bottom {
    padding-bottom: 0.875rem;
}

.padding-18px-bottom {
    padding-bottom: 1.125rem;
}

.padding-22px-bottom {
    padding-bottom: 1.375rem;
}

.padding-24px-bottom {
    padding-bottom: 1.5rem;
}

.padding-26px-bottom {
    padding-bottom: 1.625rem;
}

.padding-28px-bottom {
    padding-bottom: 1.75rem;
}

.padding-28px-bottom-sm-16 {
    padding-bottom: 1.75rem;
}

.padding-32px-bottom {
    padding-bottom: 2rem;
}

.padding-40px-bottom, .padding-40px-bottom-sm-24 {
    padding-bottom: 2.5rem;
}

.padding-44px-bottom {
    padding-bottom: 2.75rem;
}

.padding-52px-bottom {
    padding-bottom: 3.25rem;
}

.padding-54px-bottom {
    padding-bottom: 3.375rem;
}

.padding-56px-bottom, .padding-56px-bottom-sm-16 {
    padding-bottom: 3.5rem;
}

.padding-94px-bottom {
    padding-bottom: 5.875rem;
}

.padding-94px-bottom-sm-16 {
    padding-bottom: 5.875rem;
}

// padding-top
.padding-7px-top {
    padding-top: 0.438rem;
}

.padding-10px-top {
    padding-top: 0.625rem;
}


.padding-12px-top {
    padding-top: 0.75rem;
}

.padding-14px-top {
    padding-top: 0.875rem;
}

.padding-20px-top {
    padding-top: 1.25rem;
}

.padding-24px-top {
    padding-top: 1.5rem;
}

.padding-30px-top {
    padding-top: 1.875rem;
}

.padding-32px-top {
    padding-top: 2rem;
}

.padding-36px-top {
    padding-top: 2.25rem;
}

.padding-42px-top {
    padding-top: 2.625rem;
}

.padding-60px-top {
    padding-top: 3.75rem;
}

.padding-64px-top {
    padding-top: 4rem;
}

.padding-64px-top-sm-16 {
    padding-top: 4rem;
}

.padding-74px-top {
    padding-top: 4.625rem;
}

.padding-100px-top {
    padding-top: 6.25rem;
}

.padding-120px-top {
    padding-top: 7.5rem;
}

.padding-160px-top {
    padding-top: 10rem;
}

.padding-180px-top {
    padding-top: 11.25rem;
}

// margin-auto
.margin-auto {
    margin: auto;
}

// margin-top


.margin--2-top {
    margin-top: -2px;
}

.margin-2px-top {
    margin-top: 2px;
}

.margin-3px-top {
    margin-top: 3px;
}

.margin-9px-top {
    margin-top: 0.5625rem;
}

.margin-12px-top {
    margin-top: 0.75rem;
}

.margin-14px-top {
    margin-top: 0.875rem;
}

.margin-14px-md-top {
    margin-top: 0.875rem;
}

.margin-16px-top {
    margin-top: 1rem;
}

.margin-18px-top {
    margin-top: 1.125rem;
}

.margin-20px-top {
    margin-top: 1.25rem;
}

.margin-22px-top {
    margin-top: 1.375rem;
}

.margin-22px-top {
    margin-top: 1.375rem;
}

.margin-24px-top {
    margin-top: 1.5rem;
}

.margin-24px-top-sm-12 {
    margin-top: 1.5rem;
}

.margin-24px-top-sm-16 {
    margin-top: 1.5rem;
}

.margin-26px-top {
    margin-top: 1.625rem;
}

.margin-26px-top-sm-16 {
    margin-top: 1.625rem;
}

.margin-26px-top-sm-8 {
    margin-top: 1.625rem;
}


.margin-30px-top, .margin-30px-top-sm-16 {
    margin-top: 1.875rem;
}

.margin-32px-top {
    margin-top: 2rem;
}

.margin-32px-top-sm-16 {
    margin-top: 2rem;
}

.margin-34px-top {
    margin-top: 2.125rem;
}

.margin-36px-top {
    margin-top: 2.25rem;
}

.margin-38px-top, .margin-38px-top-sm-24 {
    margin-top: 2.375rem;
}

.margin-40px-top {
    margin-top: 2.5rem;
}

.margin-40px-top-sm-16 {
    margin-top: 2.5rem
}

.margin-40px-lg-top {
    margin-top: 2.5rem;
}

.margin-42px-top {
    margin-top: 2.625rem;
}

.margin-42px-top-sm-16 {
    margin-top: 2.625rem;
}

.margin-48px-top {
    margin-top: 3rem;
}

.margin-48px-top-sm-16 {
    margin-top: 3rem;
}

.margin-50px-top {
    margin-top: 3.125rem;
}

.margin-50px-top-sm-16 {
    margin-top: 3.125rem;
}

.margin-56px-top {
    margin-top: 3.5rem;
}

.margin-56px-top-sm-16,  .margin-56px-top-sm-0 {
    margin-top: 3.5rem;
}

.margin-58px-top {
    margin-top: 3.625rem;
}

.margin-64px-top {
    margin-top: 4rem;
}

.margin-80px-top {
    margin-top: 5rem;
}

.margin-82px-top {
    margin-top: 5rem;
}

.margin-88px-top {
    margin-top: 5.5rem;
}

.margin-120px-top {
    margin-top: 7.5rem;
}

.margin-120px-lg-top {
    margin-top: 7.5rem;
}

.margin-134px-top {
    margin-top: 8.25rem;
}

// margin-bottom
.margin-10px-bottom {
    margin-bottom: 0.625rem;
}

.margin-12px-bottom {
    margin-bottom: 0.75rem;
}

.margin-16px-bottom {
    margin-bottom: 1rem;
}

.margin-18px-bottom {
    margin-bottom: 1.125rem;
}

.margin-20px-bottom {
    margin-bottom: 1.25rem;
}

.margin-20px-bottom-sm-10 {
    margin-bottom: 1.25rem;
}

.margin-22px-bottom {
    margin-bottom: 1.375rem;
}

.margin-24px-bottom {
    margin-bottom: 1.5rem;
}

.margin-24px-bottom-sm-16 {
    margin-bottom: 1.5rem;
}


.margin-26px-bottom {
    margin-bottom: 1.625rem;
}

.margin-28px-bottom-sm-16 {
    margin-bottom: 1.75rem;
}

.margin-30px-bottom {
    margin-bottom: 1.875rem;
}

.margin-32px-bottom {
    margin-bottom: 2rem;
}

.margin-32px-bottom-sm-16 {
    margin-bottom: 2rem;
}


.margin-34px-bottom {
    margin-bottom: 2.125rem;
}

.margin-36px-bottom {
    margin-bottom: 2.25rem;
}

.margin-40px-bottom {
    margin-bottom: 2.5rem;
}

.margin-40px-bottom-sm-16 {
    margin-bottom: 2.5rem;
}

.margin-42px-bottom {
    margin-bottom: 2.625rem;
}

.margin-46px-bottom {
    margin-bottom: 2.875rem;
}

.margin-46px-bottom-sm-16 {
    margin-bottom: 2.875rem;
}

.margin-48px-bottom {
    margin-bottom: 3rem;
}

.margin-54px-bottom {
    margin-bottom: 3.375rem;
}

.margin-56px-bottom {
    margin-bottom: 3.5rem;
}

.margin-72px-bottom {
    margin-bottom: 4.5rem;
}

.margin-72px-bottom-sm-16 {
    margin-bottom: 4.5rem;
}

.margin-80px-bottom {
    margin-bottom: 5rem;
}

.margin-118px-bottom {
    margin-bottom: 7.375rem;
}

// margin-left
.margin-10px-left {
    margin-left: 0.625rem;
}

.margin-18px-left {
    margin-left: 1.125rem;
}

.margin-28px-left {
    margin-left: 1.75rem;
}

.margin-36px-left {
    margin-left: 2.25rem;
}

.margin-42px-left {
    margin-left: 2.625rem;
}

.margin-56px-left {
    margin-left: 3.5rem;
}

// margin-right
// Define a Sass mixin with dynamic margin-top values in rem
@mixin margin-right-classes {
    @for $i from 1 through 4 {
        $value: $i * 2; // Calculate multiples of 2px

        .margin-#{$value}px-right {
            margin-right: #{$value * 0.063}rem; // Convert to rem (assuming 1px = 0.063rem)
        }
    }
}

// Use the mixin to generate the classes
@include margin-right-classes;

.margin-14px-right {
    margin-right: 0.875rem;
}

.margin-22px-right {
    margin-right: 1.375rem;
}

.margin-26px-right {
    margin-right: 1.625rem;
}

.margin-32px-right {
    margin-right: 2rem;
}

.margin-34px-right {
    margin-right: 2.125rem;
}

.margin-38px-right {
    margin-right: 2.375rem;
}

.margin-40px-right {
    margin-right: 2.5rem;
}

.margin-56px-right {
    margin-right: 3.5rem;
}

.margin-64px-right {
    margin-right: 4rem;
}

.margin-68px-right {
    margin-right: 4.25rem;
}

.margin-106px-right {
    margin-right: 6.625rem;
}

// Margin block end
.margin-block-end-0 {
    margin-block-end: 0;
}

// z-index
.z-index-0 {
    z-index: 0;
}

.z-index-1 {
    z-index: 1;
}

.z-index-2 {
    z-index: 2;
}

.z-index-8 {
    z-index: 8;
}

.z-index-9 {
    z-index: 9;
}

.z-index-10 {
    z-index: 10;
}

.z-index-12 {
    z-index: 12;
}

// cursor
.cursor-pointer {
    cursor: pointer;
}

.cursor-text {
    cursor: text;
}

.cursor-default {
    cursor: default !important;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

//object-fit
.object-fit-contain {
    object-fit: contain;
}

.object-fit-cover {
    object-fit: cover;
}

// overflow
.overflow-sm-hidden {
    overflow: hidden;
}

// outline
.no-outline {
    outline: none;
}

.outline-1px-solid {
    outline: 1px solid $primary-color;
}

// left
.left-0 {
    left: 0;
}

.left--2 {
    left: -2rem !important;
}

.left--7 {
    left: -7px !important;
}

.left-15px {
    left: 15px !important;
}

.left-90px {
    left: 90px;
}

.left-100px {
    left: 100px;
}

.left-150px {
    left: 150px;
}

.left-175px {
    left: 10.937rem;
}
// top
.top-0 {
    top: 0;
}

.top--1 {
    top: -1px;
}

.top--3 {
    top: -3px;
}

.top--4 {
    top: -4px !important;
}

.top--7 {
    top: -7px !important;
}

.top--12px {
    top: -0.75rem;
}

.top--14 {
    top: -0.875rem;
}

.top--18 {
    top: -1.125rem;
}

.top--22 {
    top: -1.375rem;
}

.top--28 {
    top: -1.75rem;
}

.top--70 {
    top: -4.375rem;
}

.top-9px {
    top: 0.562rem;
}

.top-12px {
    top: 0.75rem;
}

.top-14px {
    top: 0.875rem;
}

.top-16px {
    top: 1rem;
}

.top-20px {
    top: 1.25rem;
}

.top-25 {
    top: 25%;
}

.top-26px {
    top: 1.562rem;
}

.top-60px {
    top: 3.75rem;
}

.top-64px {
    top: 4rem;
}

.top-66px {
    top: 4.125rem;
}

.top-140px {
    top: 8.75rem;
}

// right
.right-0 {
    right: 0 !important;
}

.right-5px {
    right: 0.3125rem !important;
}

.right-10px {
    right: 0.625rem;
}

.right-12px {
    right: 0.75rem
}

.right-14px {
    right: 0.875rem;
}

.right-18px {
    right: 1.125rem;
}

.right-20px {
    right: 1.25rem;
}

.right-24 {
    right: 1.5rem;
}

.right-48 {
    right: 3rem;
}

.right--3 {
    right: -3px;
}

.right--16px {
    right: -1rem;
}

.right--50 {
    right: -50px;
}

// bottom 
.bottom-10px{
    bottom: 0.625rem;
}

.bottom-10 {
    bottom: 10%;
}

// vertical align
.vertical-sub {
    vertical-align: sub !important;
}

.vertical-sup {
    vertical-align: super;
}
// align items
.align-items-flex-start {
    align-items: flex-start;
}

// truncate
.truncate-first-line {
    display: -webkit-box;
    white-space: normal !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncate-second-line {
    display: -webkit-box;
    white-space: normal !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}
.truncate-fourth-line {
    display: -webkit-box;
    white-space: normal !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
}

// white-space
.white-space-nowrap {
    white-space: nowrap;
}

// opacity
.opacity-15 {
    opacity: 0.15;
}

.opacity-7 {
    opacity: 0.7;
}

.opacity-6 {
    opacity: 0.6;
}

// overflow

.overflowX-auto {
    overflow-x: auto;
}

.overflowX-hidden {
    overflow-x: hidden;
}

.overflowY-auto {
    overflow-y: auto;
}

.overflowY-hidden {
    overflow-y: hidden;
}


.overflow-wrap-anywhere {
    overflow-wrap: anywhere;
}

.Toastify__toast-container {
    width: 350px!important;
}

.Toastify__toast-body {
    font-family: "Inter-Regular";
    color: $black;
    padding: 6px 22px 6px 6px !important;
    html[data-theme="dark"] & {
        background-color: #3a3838;
    }
}

.Toastify__toast-theme--light {
    html[data-theme="dark"] & {
        background-color: #3a3838
    }
}

.Toastify__toast--success .Toastify__toast-icon svg {
    fill: $caribbean-green;
    /* Change the fill color to purple or your desired color */
}

.Toastify__progress-bar--success {
    background: $caribbean-green !important;
}

.hover-underline {
    position: relative;

    &:hover {
        &::after {
            width: 100%;
        }
    }

    &::after {
        content: '';
        position: absolute;
        height: 1px;
        bottom: -2px;
        left: 0;
        width: 0;
        background-color: $black;
        transition: width ease 0.4s;
    }
}

.hover-primary-color {
    &:hover {
        color: $primary-color;
    }
}

.e-forgot-pin {
    &:hover {
        &::before {
            width: 100%;
        }
    }

    &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0.062rem;
        background-color: $primary-color;
        bottom: -0.125rem;
        left: 0;
        transition: ease width 0.4s;
    }
}

.e-chip-hover {
    box-shadow: 0 0.75rem 0.75rem 0 rgba(50, 50, 71, 0.08);
}

// rotate gif
.e-rotate-gif {
    transform: rotate(270deg);
    margin-right: 10px;
}

.e-table-scroll-indicator, .e-table-scroll-indicator-watchlist, .e-table-scroll-indicator-sip{
    display: none;
}

.e-kyc-user-type-card {
    padding: 20px;
}
.e-kyc-investment-type {
    white-space: nowrap;
    font-size: 20px;
}

svg{
    outline: none;
}

.e-share-icon-wrapper {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.e_transition_center {
    left: 0;
    top: 50%;
    transform: translate(22px, -50%);
}

.e_transition_top {
    top: -0.7rem;
    left: 15px;
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    margin: 0;
    transition: top 0.5s ease;
}

.e-arrow-down {
    transform: rotate(90deg);
    transition: transform 0.3s ease;
}

.e-arrow-up {
    transform: rotate(270deg);
    transition: transform 0.3s ease;
}

.e-primary-font-medium {
    font-family: 'Poppins';
    font-weight: 500;
}

.modal-content {
    background-color: var(--modal-bg-color) !important;
    html[data-theme="dark"] & {
        border: 1px solid $gunmetal-gray !important;
    }
}

.e-filter-invert-1 {
    html[data-theme="dark"] & {
        filter: invert(1) brightness(09);
    }
}