@import '../global.scss';

.e_active_btn {
    @extend %font16;
    html[data-theme="dark"] & {
        background-color: transparent;
        transition: ease-in-out 0.3s;
            &:hover {
                background-color: #131313 !important;
            }
    }
    &:hover {
        background: $ateneo-blue !important;
        color: $white
    }
}

// inactive btn style
.e_inactive_btn {
    @extend .e_active_btn;
    pointer-events: none;
    opacity: 0.8;
}